import { AnalyticsContext } from "@apollosproject/canvas-ui-web";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { useEffect, useRef } from "react";

import "./app.css";

import { analytics } from "./analytics";

export function Layout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const previousPageRef = useRef<string>();

  useEffect(() => {
    const currentPage = window.location.href;
    const previousPage = previousPageRef.current;

    if (currentPage === previousPage) return;
    previousPageRef.current = currentPage;

    analytics.page({
      ...(previousPage ? { referrer: previousPage } : {}),
    });
  }, [location]);

  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="bg-system-screen dark:bg-system-screen-dark h-full">
        <AnalyticsContext.Provider value={analytics}>
          {children}
        </AnalyticsContext.Provider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}
